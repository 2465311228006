import React, { useState } from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Button, ButtonGroup } from '@mui/material';
import axios from 'axios';
import api from '../../Api';

export default function CustomListForTagging({ text, id, clients, onRemove }: { text: any, id: number, clients: string[] | null, onRemove: () => void }) {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    interface ClientData {
        name: string;
        instanceid: string;
    };
    const handleClick = async (itemText: string, client: string) => {
        console.log("Clicked item text:", itemText);
        const clientData: ClientData = {
            name: client,
            instanceid: itemText,
        };
        try {
            const response = await api.post('http://localhost:5000/api/client/instanceid/add', clientData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log(response.data)
            onRemove();
        } catch (error) {
            console.error('Error adding tag to cur data:', error);
            throw error;
        }
    };

    return (
        <ListItem
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                position: 'relative',
                '&:hover $button': {
                    visibility: 'visible',
                },
            }}
            key={id}
        >
            <ListItemText
                primary={text}
                sx={{
                    opacity: isHovered ? 0.1 : 1,
                }}
            />
            <ButtonGroup variant="text" aria-label="Basic button group" sx={{
                visibility: isHovered ? 'visible' : 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}
            >
                {clients?.map((item, index) => (
                    <Button
                        key={index}

                        onClick={() => handleClick(text, item)}
                    >
                        {item}
                    </Button>
                ))}
            </ButtonGroup>

        </ListItem>
    );
};