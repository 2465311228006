import { Box, Button, Card, CardContent, CardHeader, Grid, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import { PieChart, Pie, ResponsiveContainer, Tooltip, Cell } from 'recharts';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import api from '../../Api';
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF5733', '#DAF7A6', '#BDBDC3', '#C70039', '#900C3F', '#581845'];
export default function CostByClient({ costtype }: { costtype: string }) {
    const navigate = useNavigate();
    const [responseData, setResponseData] = useState([]);
    const [fullData, setFullData] = useState(false);
    const [sortedData, setSortedData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response: AxiosResponse = await api.get(`/api/cost/client?type=${costtype}`)
                const sortedData = response.data.sort((a: { Cost: string; }, b: { Cost: string; }) => parseFloat(b.Cost) - parseFloat(a.Cost));
                // const firstFiveItems = sortedData.slice(0, 5);
                setResponseData(response.data);
                setSortedData(sortedData);
            } catch (error) {

            }
        };
        fetchData();
    }, []);

    const handleClick = (path: string) => {
        navigate(path);
    };
    return (
        <Card>
            <CardHeader
                subheader="Cost by Client"
            />
            <CardContent>
                <Grid container>
                    <Grid item xs={8}>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie
                                    dataKey="Cost"
                                    data={responseData}
                                    fill="#8884d8"
                                    nameKey="CostCenter"
                                    outerRadius={90}
                                    cx="50%"
                                    cy="50%"
                                >
                                    {(responseData || []).map((_entry: any, index: number) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </Grid>
                    <Grid item xs={4}>
                        <List>
                            {(responseData || []).map((item: {
                                CostCenter: string;
                                Cost: string;
                                item: any
                            }) => (
                                <ListItem disablePadding>
                                    <Button
                                        key={item.CostCenter.toString()}
                                        sx={{ padding: 0 }}
                                        variant="text"
                                        onClick={() => handleClick(`/cost/${item.CostCenter}/${costtype}`)}
                                    >
                                        {`${item.CostCenter.toString()}: ${item.Cost.toString()}`}
                                    </Button>
                                </ListItem>

                            ))}
                        </List>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
