import { Box, Card, CardContent, CardHeader, Grid, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { PieChart, Pie, ResponsiveContainer, Tooltip, Bar, BarChart, CartesianGrid, Legend, Rectangle, XAxis, YAxis } from 'recharts';
import api from '../../Api';
const apiUrl = 'http://localhost:5000/api/cost/service';

export default function CostByService({costtype}: {costtype: string}) {
    const [responseData, setResponseData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response: AxiosResponse = await api.get(`/api/cost/service?type=${costtype}`);
                setResponseData(response.data);
            } catch (error) {

            }
        };
        fetchData();
    }, []);
    return (
        <Card>
            <CardHeader
                subheader="Cost by Service"
            />
            <CardContent>
                    <BarChart
                        width={1400}
                        height={300}
                        data={responseData}

                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Service" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Cost" fill="#8884d8" />
                    </BarChart>
            </CardContent>
        </Card>
    );
}
