import { Card, CardContent, CardHeader, Grid, List, ListItemText } from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import { useState, useEffect } from 'react';
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts';
import api from '../../Api';

const COLORS = ['#00C49F', '#FF5733', '#0088FE', '#FFBB28', '#FF8042', , '#DAF7A6', '#BDBDC3', '#C70039', '#900C3F', '#581845'];

interface DataItem {
    Region: string;
    Cost: string;
}
export default function CostByRegion({ costtype }: { costtype: string }) {
    const [responseData, setResponseData] = useState([]);
    const [fullData, setFullData] = useState(false);
    const [sortedData, setSortedData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response: AxiosResponse = await api.get(`/api/cost/region?type=${costtype}`)
                const sortedData = response.data.sort((a: { Cost: string; }, b: { Cost: string; }) => parseFloat(b.Cost) - parseFloat(a.Cost));
                // const firstFiveItems = sortedData.slice(0, 5);
                setResponseData(response.data);
                setSortedData(sortedData);
                console.log(responseData)
            } catch (error) {

            }
        };
        fetchData();
    }, []);
    const handleShowMore = () => {
        setFullData(true); // Toggle full data visibility
    };
    return (
        <Card>
            <CardHeader
                subheader="Cost by Region"
            />
            <CardContent>
                <Grid container>
                    <Grid item xs={6}>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart >
                                <Pie
                                    dataKey="Cost"
                                    data={responseData}
                                    fill="#8884d8"
                                    nameKey="Region"
                                    outerRadius={90}
                                    cx="50%"
                                    cy="50%"
                                >
                                    {responseData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </Grid>
                    <Grid item xs={6}>
                        <List>
                            {sortedData.slice(0, 5).map((item: {
                                Region: string;
                                Cost: string;
                                item: any
                            }) => (
                                <ListItemText primary={`${item.Region.toString()}: ${item.Cost.toString()}`} key={item.Region.toString()} />
                            ))}
                            <ListItemText primary="..." />
                            {/* <Button variant="contained" color="primary" onClick={handleShowMore}>Show More</Button> */}
                        </List>
                    </Grid>
                </Grid>


                {/* <Modal
                    open={fullData}
                    onClose={() => setFullData(false)}
                    aria-labelledby="full-data-modal-title"
                    aria-describedby="full-data-modal-description"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: background overlay
                    }}
                >
                    <Box sx={{ width: 300, bgcolor: 'background.paper', p: 2, borderRadius: 1 }}>
                        <Typography id="full-data-modal-title" variant="h6" component="h2">
                            Full Data
                        </Typography>
                        <Typography id="full-data-modal-description" sx={{ mt: 2 }}>
                            <ResponsiveContainer width={400} height={400}>
                                <PieChart>
                                    <Pie
                                        dataKey="Cost"
                                        data={responseData}
                                        fill="#8884d8"
                                        nameKey="Region"
                                        innerRadius={60}
                                        outerRadius={80}
                                    >
                                        {responseData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                </PieChart>
                            </ResponsiveContainer>
                            <List>
                                {responseData.map((item: DataItem) => (
                                    <ListItemText primary={`${item.Region.toString()}:${item.Cost.toString()}`} />
                                ))}
                            </List>
                        </Typography>
                    </Box>
                </Modal> */}
            </CardContent>
        </Card>
    );
}
