import { Box, CardHeader, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import api from '../../Api';

export default function TotalCost({costtype}: {costtype: string}) {
  const [responseData, setResponseData] = useState([]);
  useEffect(() => {
      const fetchData = async () => {
          try {
              const response: AxiosResponse = await api.get(`/api/cost/total?type=${costtype}`)
              setResponseData(response.data.totalCost);
              console.log(response.data.totalCost)
          } catch (error) {

          }
      };
      fetchData();
  }, []);
  return (
    <Card>
      <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h6">Total Cost</Typography>
        <Typography>{responseData}</Typography>
      </CardContent>
    </Card>
  );
}