// Breadcrumbs.tsx
import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';

interface BreadcrumbItem {
    title: string;
    link: string;
}

const HeaderCrums: React.FC = () => {
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);

    useEffect(() => {
        const paths = window.location.pathname.split('/').slice(1);
        const newBreadcrumbs = paths.map((p, index) => ({
            title: p,
            link: `/${paths.slice(0, index + 1).join('/')}`,
        }));
        setBreadcrumbs(newBreadcrumbs);
    }, []);

    return (
        <Breadcrumbs aria-label="breadcrumb">
            {breadcrumbs.map((b, index) =>
                index !== breadcrumbs.length - 1 ? (
                    <Link key={b.title} underline="hover" color="inherit">
                        {b.title}
                    </Link>
                ) : (
                    <Typography key={b.title} color="text.primary">
                        {b.title}
                    </Typography>
                )
            )}
        </Breadcrumbs>
    );
};

export default HeaderCrums;
