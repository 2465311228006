import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import api from '../../../Api';


export default function InstanceIds({ costtype, client }: { costtype: string, client: string }) {
    const columns: GridColDef[] = [
        { field: 'NameOrID', headerName: 'Name or ID', width: 500 },
        { field: 'ResourceType', headerName: 'ResourceType', width: 200 },
        { field: 'Provider', headerName: 'Provider', width: 200 },
        { field: 'ResourceGroup', headerName: 'ResourceGroup', width: 500 },
        
    ];

    const [responseData, setResponseData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response: AxiosResponse = await api.get(`/api/cost/client/list/instanceids?type=${costtype}&&name=${client}`)
                setResponseData(response.data);
                console.log(response.data)
            } catch (error) {

            }
        };
        fetchData();
    }, []);
    return (
        <DataGrid
            rows={responseData}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            getRowId={(row) => row.Id}
        />
        // <TableContainer component={Paper}>
        //   <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        //     <TableHead>
        //       <TableRow>
        //         <TableCell>Name or Id</TableCell>
        //         <TableCell align="right">Resource Type</TableCell>
        //         <TableCell align="right">Provider</TableCell>
        //         <TableCell align="right">Resource Group</TableCell>
        //       </TableRow>
        //     </TableHead>
        //     <TableBody>
        //       {responseData.map((row: {
        //         NameOrID: string;
        //         ResourceType: string;
        //         Provider: string;
        //         ResourceGroup: string;

        //       }) => (
        //         <TableRow
        //           key={row.NameOrID}
        //           sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        //         >
        //           <TableCell component="th" scope="row">
        //             {row.NameOrID}
        //           </TableCell>
        //           <TableCell align="right">{row.ResourceType}</TableCell>
        //           <TableCell align="right">{row.Provider}</TableCell>
        //           <TableCell align="right">{row.ResourceGroup}</TableCell>
        //         </TableRow>
        //       ))}
        //     </TableBody>
        //   </Table>
        // </TableContainer>
    );
}