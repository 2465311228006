import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import CostByService from '../ClientCosts/CostByService/CostByService';
import CostByRegion from '../ClientCosts/CostByRegion/CostByRegion';
import { Box, Grid, Typography } from '@mui/material';
import TotalCost from '../ClientCosts/TotalCost/TotalCost';
import InstanceIds from '../ClientCosts/InstanceIds/InstanceIds';
import { capitalize } from '@mui/material/utils';
import BreadCrumb from '../BreadCrumb/BreadCrumb';

const ClientCostDetails = () => {
    const { client, type } = useParams();
    const [clientName, setClientName] = useState(capitalize(client || ''));
    return (
        <Box sx={{
            minWidth: 'calc(100vw - 110px)',
            padding: '10px'
        }}>
                                <BreadCrumb></BreadCrumb>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <TotalCost client={client || ''} costtype={type || ''}></TotalCost>
                </Grid>
                <Grid item xs={6}>
                    <CostByService client={client || ''} costtype={type || ''}></CostByService>
                </Grid>
                <Grid item xs={4}>
                    <CostByRegion client={client || ''} costtype={type || ''}></CostByRegion>
                </Grid>
                <Grid item xs={12}>
                    <InstanceIds client={client || ''} costtype={type || ''}></InstanceIds>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ClientCostDetails;