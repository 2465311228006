import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import Divider from '@mui/material/Divider';
import CostByClient from '../CostByClient/CostByClient'
import CostByRegion from '../CostByRegion/CostByRegion'
import TotalCost from '../TotalCost/TotalCost'
import axios, { AxiosResponse } from 'axios'
import CostByService from '../CostByService/CostByService'
import api from '../../Api'
const apiUrl = 'http://localhost:5000/api/get_cur_data';

interface ApiResponse {
    [key: string]: any;
}
const Home = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const currentMonthName = monthNames[currentMonth];
    const previousMonthName = monthNames[currentMonth - 1];
    // const [responseData, setResponseData] = useState<ApiResponse | null>(null);
    // const [error, setError] = useState<string | null>(null);

    // const [totalCost, setTotalCost] = useState<ApiResponse | null>(null);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response: AxiosResponse<ApiResponse> = await api.get('/api/get_cur_data');
    //             console.log(response.data)
    //             setResponseData(response.data[0]["CostByClient"]);
    //             setTotalCost(response.data[0]["TotalCost"])
    //         } catch (error) {

    //         }
    //     };
    //     fetchData();
    // }, []);
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <Typography variant="h6">Azure Cloud : {currentMonthName}</Typography>
                    </Box>
                    <Divider />
                </Grid>
                <Grid item xs={2}>
                    <TotalCost costtype={"daily"} />
                </Grid>
                <Grid item xs={6}>
                    <CostByClient costtype={"daily"} />
                </Grid>
                <Grid item xs={4}>
                    <CostByRegion costtype={"daily"} />
                </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CostByService costtype={"daily"}/>
                </Grid>
            </Grid>
            <Divider sx={{ paddingTop: '10px' }} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <Typography variant="h6">Azure Cloud: {previousMonthName}</Typography>
                    </Box>
                    <Divider />
                </Grid>
                <Grid item xs={2}>
                    <TotalCost costtype={"monthly"} />
                </Grid>
                <Grid item xs={6}>
                    <CostByClient costtype="monthly" />
                </Grid>
                <Grid item xs={4}>
                    <CostByRegion costtype="monthly" />
                </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CostByService costtype={"monthly"}/>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Home